import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import { divIcon, point, Marker as LeafletMarker } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './App.css';
import locationsData from './data/locations.json';
import ehboIcon from './ehbo.svg';
import toiletIcon from './toilet.svg';
import horecaIcon from './horeca.svg';

interface Program {
  programNumber?: string;
  campaignName: string;
  description: string;
}

interface Location {
  name: string;
  latitude: string;
  longitude: string;
  type: string;
}

interface LocationData {
  location: Location;
  programs: Program[];
}

const typeColors: { [key: string]: string } = {
  EHBO: 'rgb(19, 145, 85)',
  Opening: 'rgb(237, 27, 35)',
  Binnen: 'rgb(46, 118, 92)',
  Buiten: 'rgb(0, 102, 179)',
  Mobiel: 'rgb(211, 42, 223)',
  Toilet: 'rgb(255, 204, 0)',
  Horeca: 'rgb(239, 107, 34)'
};

const getColorByType = (type: string): string => {
  return typeColors[type] || 'rgb(128, 128, 128)'; // Default to gray if type is not found
};

const getIconByType = (type: string): string => {
  switch (type) {
    case 'EHBO':
      return `<img src="${ehboIcon}" alt="EHBO" style="width: 28px; height: 28px;" />`;
    case 'Toilet':
      return `<img src="${toiletIcon}" alt="Toilet" style="width: 24px; height: 24px;" />`;
    case 'Horeca':
      return `<img src="${horecaIcon}" alt="Horeca" style="width: 24px; height: 24px;" />`;
    default:
      return '';
  }
};

const createCustomIcon = (number: string | undefined, color: string, type: string) => {
  const isEHBO = type === 'EHBO';
  const size = isEHBO ? 36 : 30;
  const iconHtml = number 
    ? `<div style="background-color: ${color}; width: ${size}px; height: ${size}px; display: flex; justify-content: center; align-items: center; border-radius: 50%;"><span style="color: white; font-weight: bold; font-size: ${isEHBO ? '12px' : '10x'};">${number}</span></div>` 
    : `<div style="background-color: ${color}; width: ${size}px; height: ${size}px; display: flex; justify-content: center; align-items: center; border-radius: 50%;">${getIconByType(type)}</div>`;
  
  return divIcon({
    className: 'custom-icon',
    html: iconHtml,
    iconSize: point(size, size),
    iconAnchor: point(size/2, size/2)
  });
};

const EventList: React.FC<{ 
  locations: LocationData[], 
  onLocationClick: (location: LocationData) => void 
}> = ({ locations, onLocationClick }) => {
  const sortedLocations = locations.slice().sort((a, b) => {
    if (a.location.type === 'EHBO' && b.location.type !== 'EHBO') return -1;
    if (b.location.type === 'EHBO' && a.location.type !== 'EHBO') return 1;
    const aHasNumber = a.programs.some(program => program.programNumber) ? 1 : 0;
    const bHasNumber = b.programs.some(program => program.programNumber) ? 1 : 0;
    return bHasNumber - aHasNumber;
  });

  return (
    <div className="event-list">
      {sortedLocations.map((locationData, index) => (
        <div 
          key={`${locationData.location.name}-${index}`} 
          className="event-item"
          onClick={() => onLocationClick(locationData)}
        >
          {locationData.programs.length > 0 ? (
            locationData.programs.map((program, programIndex) => (
              <div key={programIndex} className="popup-content" style={{ borderTop: `3px solid ${getColorByType(locationData.location.type)}` }}>
                <div className="popup-header">
                  <h2 className="campaign-name">{program.campaignName}</h2>
                  {(program.programNumber || locationData.location.type === 'EHBO') && (
                    <div 
                      className="program-number-circle" 
                      style={{ 
                        backgroundColor: getColorByType(locationData.location.type),
                        width: locationData.location.type === 'EHBO' ? '40px' : '30px',
                        height: locationData.location.type === 'EHBO' ? '40px' : '30px',
                        fontSize: locationData.location.type === 'EHBO' ? '14px' : '12px'
                      }}
                    >
                      {locationData.location.type === 'EHBO' ? 'EHBO' : program.programNumber}
                    </div>
                  )}
                </div>
                <h3 className="location-name">{locationData.location.name}</h3>
                <p className="description">{program.description}</p>
              </div>
            ))
          ) : (
            <div className="popup-content" style={{ borderTop: `3px solid ${getColorByType(locationData.location.type)}` }}>
              <h3 className="location-name">{locationData.location.name}</h3>
              <p className="description">No specific programs listed for this location.</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const Legend = () => (
  <div className="legend">
    <h4>Legenda</h4>
    <ul>
      {Object.entries(typeColors).map(([type, color]) => (
        <li key={type}>
          <span style={{ backgroundColor: color }}></span> {type}
        </li>
      ))}
    </ul>
  </div>
);

const HighlightableMarker: React.FC<{
  position: [number, number];
  icon: L.DivIcon;
  isHighlighted: boolean;
  children: React.ReactNode;
}> = ({ position, icon, isHighlighted, children }) => {
  const markerRef = useRef<LeafletMarker>(null);

  useEffect(() => {
    if (isHighlighted && markerRef.current) {
      const el = markerRef.current.getElement();
      el?.classList.add('highlighted-marker');
      setTimeout(() => {
        el?.classList.remove('highlighted-marker');
      }, 3000);
    }
  }, [isHighlighted]);

  return (
    <Marker position={position} icon={icon} ref={markerRef}>
      {children}
    </Marker>
  );
};

const MapComponent: React.FC<{ 
  selectedLocation: LocationData | null;
  highlightedLocationId: string | null;
}> = ({ selectedLocation, highlightedLocationId }) => {
  const map = useMap();

  useEffect(() => {
    if (selectedLocation) {
      map.flyTo(
        [parseFloat(selectedLocation.location.latitude), parseFloat(selectedLocation.location.longitude)],
        19,
        {
          duration: 1.5,
        }
      );
    }
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
  }, [selectedLocation, map]);

  useMapEvents({
    click: () => {
      map.invalidateSize();
    },
  });

  return null;
};

const App: React.FC = () => {
  const [view, setView] = useState<'map' | 'list'>('map');
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<LocationData | null>(null);
  const [highlightedLocationId, setHighlightedLocationId] = useState<string | null>(null);
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (view === 'map') {
      setTimeout(() => {
        if (mapRef.current) {
          mapRef.current.invalidateSize();
        }
      }, 100);
    }
  }, [view]);

  const handleLocationClick = (location: LocationData) => {
    setSelectedLocation(location);
    setHighlightedLocationId(location.location.name);
    setView('map');
    
    // Remove highlight after 3 seconds
    setTimeout(() => {
      setHighlightedLocationId(null);
    }, 3000);
  };

  const handleMapReady = useCallback(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }
  }, []);

  if (loading) {
    return (
      <div className="splash-screen">
        <img src="/logo_toneelroute.svg" alt="Toneelroute Logo" className="splash-logo" />
        <p className="splash-text">Loading...</p>
      </div>
    );
  }

  return (
    <div className="app">
      <header className="app-header">
        <img src="/logo_toneelroute.svg" alt="Toneelroute Logo" className="app-logo" />
        <h1 className="app-title">Toneelroute Vianen</h1>
        <div style={{ width: '30px' }}></div>
      </header>
      <main className="app-main">
        <div className={`view-container ${view}`}>
          <div className="map-view">
            <MapContainer 
              center={[51.99442763, 5.09118352]} 
              zoom={17} 
              maxZoom={19}
              style={{ height: '100%', width: '100%' }}
              ref={mapRef}
              whenReady={handleMapReady}
            >
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                maxZoom={19}
              />
              {locationsData.map((locationData: LocationData, index: number) => (
                <HighlightableMarker
                  key={`${locationData.location.name}-${index}`}
                  position={[parseFloat(locationData.location.latitude), parseFloat(locationData.location.longitude)]}
                  icon={createCustomIcon(
                    locationData.location.type === 'EHBO' ? 'EHBO' : locationData.programs[0]?.programNumber,
                    getColorByType(locationData.location.type),
                    locationData.location.type
                  )}
                  isHighlighted={highlightedLocationId === locationData.location.name}
                >
                  <Popup>
                    {locationData.programs.length > 0 ? (
                      locationData.programs.map((program, programIndex) => (
                        <div key={programIndex} className="popup-content" style={{ borderTop: `3px solid ${getColorByType(locationData.location.type)}` }}>
                          <div className="popup-header">
                            <h2 className="campaign-name">{program.campaignName}</h2>
                            {(program.programNumber || locationData.location.type === 'EHBO') && (
                              <div 
                                className="program-number-circle" 
                                style={{ 
                                  backgroundColor: getColorByType(locationData.location.type),
                                  width: '30px',
                                  height: '30px',
                                  fontSize: '12px'
                                }}
                              >
                                {locationData.location.type === 'EHBO' ? 'EHBO' : program.programNumber}
                              </div>
                            )}
                          </div>
                          <h3 className="location-name">{locationData.location.name}</h3>
                          <p className="description">{program.description}</p>
                        </div>
                      ))
                    ) : (
                      <div className="popup-content" style={{ borderTop: `3px solid ${getColorByType(locationData.location.type)}` }}>
                        <h3 className="location-name">{locationData.location.name}</h3>
                        <p className="description">No specific programs listed for this location.</p>
                      </div>
                    )}
                  </Popup>
                </HighlightableMarker>
              ))}
              <Legend />
              <MapComponent 
                selectedLocation={selectedLocation} 
                highlightedLocationId={highlightedLocationId}
              />
            </MapContainer>
          </div>
          <div className="list-view">
            <EventList 
              locations={locationsData} 
              onLocationClick={handleLocationClick}
            />
          </div>
        </div>
      </main>
      <footer className="app-footer">
        <div className="footer-buttons-container">
          <button className="footer-button" onClick={() => setView('map')}>Kaart</button>
          <button className="footer-button" onClick={() => setView('list')}>Lijst</button>
        </div>
      </footer>
    </div>
  );
};

export default App;